export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/4/d/e/1FAIpQLSeNuicZa3meHaU55Qv6MhOlJjsLQ_MG7Hi8pF2YfP0-aRQjog/formResponse",
    name: "entry.310942426",
    Eaddress: "entry.1069013774",
    telephone: "entry.231985470",
    email: "entry.1867632011",
    Edate: "entry.211014443",
    move: "entry.958510946",
    Saddress: "entry.1327605523",
    Sdate: "entry.2143228045",
    pay: "entry.1140085329",
    other: "entry.1230376142",
    message: "entry.932670635",
}

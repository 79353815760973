import React, { useState } from "react"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { beforeContactFormSubmit, contactFormSubmit } from "../../config"
import SocialLinks from "../utils/sociallinks"
import { ContactQuery_site_siteMetadata_contact } from "../pages/__generated__/ContactQuery"
import { motion, AnimatePresence } from "framer-motion"

import Pause from "../components/pause"
import Moving from "../components/moving"
import Subscribe from "../components/subscribe"

type FeedbackState = { [id: number]: { message?: string; type?: string } }

const Form: React.FC<{ api: string }> = ({ api }) => {
    const [feedback, setFeedback] = useState<FeedbackState>({})
    // const transactionState = useTransactionState(false)

    const [transactionState, setTransactionState] = useState(false)
    // const updateData = v => changeData({ ...data, ...v })

    const [openTab, setOpenTab] = React.useState(1)

    return (
        // <form
        //     onSubmit={event => {
        //         event.preventDefault()
        //         setTransactionState(true)

        //         const validate = beforeContactFormSubmit(data)

        //         if (validate.result) {
        //             setFeedback({})
        //             contactFormSubmit(api, validate.data)
        //                 .then(res => {
        //                     if (res.result) {
        //                         setFeedback({
        //                             4: {
        //                                 type: "success",
        //                                 message:
        //                                     "お客様からのご連絡を承りました。",
        //                             },
        //                         })
        //                     } else {
        //                         setFeedback({
        //                             4: {
        //                                 message:
        //                                     "メッセージの送信にエラーが発生しました。もう一度お試しください。",
        //                             },
        //                         })
        //                     }
        //                     setTransactionState(false)
        //                 })
        //                 .catch(err => {
        //                     setFeedback({
        //                         4: {
        //                             message:
        //                                 "メッセージの送信にエラーが発生しました。もう一度お試しください。",
        //                         },
        //                     })
        //                     setTransactionState(false)
        //                 })
        //         } else {
        //             const errs = {}

        //             validate.errors.forEach(err => {
        //                 errs[err.code] = { message: err.message }
        //             })

        //             setFeedback(errs)
        //             setTransactionState(false)
        //         }
        //     }}
        // >
        <div className="flex flex-wrap">
            <div className="w-full">
                <ul
                    className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row "
                    role="tablist"
                >
                    <motion.li
                        className="-mb-px mr-2 last:mr-0 flex-auto text-center "
                        whileHover={{ y: 5 }}
                    >
                        <a
                            className={
                                "text-base font-bold uppercase px-5 py-3 shadow-2xl rounded block leading-normal " +
                                (openTab === 1 ? "tabblue" : "tabwhite")
                            }
                            onClick={e => {
                                e.preventDefault()
                                setOpenTab(1)
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            一時休止
                        </a>
                    </motion.li>
                    <motion.li
                        className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                        whileHover={{ y: 5 }}
                    >
                        <a
                            className={
                                "text-base font-bold uppercase px-5 py-3 shadow-2xl rounded block leading-normal " +
                                (openTab === 2 ? "tabblue" : "tabwhite")
                            }
                            onClick={e => {
                                e.preventDefault()
                                setOpenTab(2)
                            }}
                            data-toggle="tab"
                            href="#link2"
                            role="tablist"
                        >
                            お引越し
                        </a>
                    </motion.li>
                    <motion.li
                        className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                        whileHover={{ y: 5 }}
                    >
                        <a
                            className={
                                "text-base font-bold uppercase px-5 py-3 shadow-2xl rounded block leading-normal " +
                                (openTab === 3 ? "tabblue" : "tabwhite")
                            }
                            onClick={e => {
                                e.preventDefault()
                                setOpenTab(3)
                            }}
                            data-toggle="tab"
                            href="#link3"
                            role="tablist"
                        >
                            お試し・購読
                        </a>
                    </motion.li>
                </ul>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                    <div className="px-4 py-5 flex-auto">
                        <div className="tab-content tab-space">
                            <AnimatePresence>
                                {openTab === 1 && (
                                    <motion.div
                                        className={
                                            openTab === 1 ? "block" : "hidden"
                                        }
                                        id="link1"
                                        key="Pause"
                                        animate={{
                                            // x: 0,
                                            opacity: 1,
                                        }}
                                        initial={{
                                            // x: 50,
                                            opacity: 0,
                                        }}
                                        exit={{
                                            // x: 50,
                                            opacity: 0,
                                        }}
                                        transition={{
                                            duration: 0.5,
                                        }}
                                    >
                                        <Pause />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {openTab === 2 && (
                                    <motion.div
                                        className={
                                            openTab === 2 ? "block" : "hidden"
                                        }
                                        id="link2"
                                        key="Moving"
                                        animate={{
                                            // x: 0,
                                            opacity: 1,
                                        }}
                                        initial={{
                                            // x: 50,
                                            opacity: 0,
                                        }}
                                        exit={{
                                            // x: 50,
                                            opacity: 0,
                                        }}
                                        transition={{
                                            duration: 0.5,
                                        }}
                                    >
                                        <Moving />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {openTab === 3 && (
                                    <motion.div
                                        className={
                                            openTab === 3 ? "block" : "hidden"
                                        }
                                        id="link3"
                                        key="Subscribe"
                                        animate={{
                                            // x: 0,
                                            opacity: 1,
                                        }}
                                        initial={{
                                            // x: 50,
                                            opacity: 0,
                                        }}
                                        exit={{
                                            // x: 50,
                                            opacity: 0,
                                        }}
                                        transition={{
                                            duration: 0.5,
                                        }}
                                    >
                                        <Subscribe />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </form>
    )
}

const Description: React.FC<{
    data: ContactQuery_site_siteMetadata_contact
}> = ({ data }) => {
    return (
        <div>
            {data.description && (
                <p className="text-color-default">{data.description}</p>
            )}
            <ul className="my-4">
                {data.mail && (
                    <li className="flex items-center">
                        <span className="text-secondary icon">
                            <Mail />
                        </span>
                        <a className="ml-4" href={"mailto:" + data.mail}>
                            {data.mail}
                        </a>
                    </li>
                )}
                {data.phone && (
                    <li className="flex items-center mt-4">
                        <span className="text-secondary icon">
                            <Phone />
                        </span>
                        <a className="ml-4" href={"tel:" + data.phone}>
                            {data.phone}
                        </a>
                        　フリーダイヤル
                        <a className="ml-4" href={"tel:" + data.free}>
                            {data.free}
                        </a>
                    </li>
                )}
                {data.address && (
                    <li className="flex items-start mt-4">
                        <span className="mt-1 text-secondary icon">
                            <MapPin />
                        </span>
                        <p className="whitespace-pre ml-4">{data.address}</p>
                    </li>
                )}
                {/* <li>
                    <SocialLinks />
                </li> */}
            </ul>
        </div>
    )
}

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormMessageProps = { show: boolean; type: string; message: string }
const FormMessage: React.FC<FormMessageProps> = ({ show, type, message }) => {
    if (!show) return null
    return <p className={`text-${type} my-2`}>{message}</p>
}

export { Form, Description }

export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/4/d/e/1FAIpQLSdeqHBA8OwjvZjvy7faespp2IMzKjarNveF752w1Re_DC3NSA/formResponse",

    name: "entry.1974696506",
    address: "entry.679737666",
    telephone: "entry.6590411",
    email: "entry.1036638796",
    Sdate: "entry.2012199548",
    start: "entry.176059698",
    Edate: "entry.325587367",
    end: "entry.1228628776",
    keep: "entry.1711915780",
    message: "entry.1801636556",
}

export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/4/d/e/1FAIpQLSdYBLdNIE2o0rPkGC_RVaf3bmVuyHtXe7r4voG7hPZNKWsFDQ/formResponse",
    name: "entry.1011223112",
    address: "entry.386312815",
    telephone: "entry.590402389",
    email: "entry.1552611696",
    sub: "entry.714582651",
    plan: "entry.1121843360",
    Sdate: "entry.217113613",
    message: "entry.1972127415",
}
